import { Filter, Selection } from "@encoway/c-services-js-client"
import { L10n } from "@encoway/l10n"

import { getCatalogService } from "../app/catalogServiceHolder"


export async function initializeTranslations() {
    const catalogService = getCatalogService()
    const lang = L10n.currentFullLocale()
    
    const productGroupFilter = Filter.productGroupFilter()
        .id("UI_TRANSLATIONS")
        .includeSubgroups(true)
    const selectionQuery = new Selection()
        .limit(1000)
        .filter(productGroupFilter)

    const translationResult = await catalogService.products(selectionQuery, lang)
    
    const translations: any = {}
    translationResult.products.forEach((translation: any) => {
        translations[translation.id] = translation.name
    })
    L10n.source("ui", { [lang]: translations }, true)
    await L10n.reloadResources(lang)
}
