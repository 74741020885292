export const TranslationConstants = {
    General: {
        CABLE: "general.cable"
    },
    Dialogs: {
        General: {
            BUTTON_PRIMARY: "dialog.general.button.primary",
            BUTTON_SECONDARY: "dialog.general.button.secondary"
        },
        LoadConfiguration: {
            PRIMARY_BUTTON: "dialog.load_configuration.button.primary",
            INPUT_ERROR_LOADED:
                "dialog.load_configuration.content.input.error_message.already_loaded",
            INPUT_ERROR_INVALID:
                "dialog.load_configuration.content.input.error_message.invalid_code",
            INPUT_ERROR:
                "dialog.load_configuration.content.input.error_message.loading_error",
            INPUT_LABEL: "dialog.load_configuration.content.input.label",
            INPUT_PLACEHOLDER:
                "dialog.load_configuration.content.input.placeholder",
            TITLE: "dialog.load_configuration.title"
        },
        State: {
            CONFIRMATION_PRIMARY_BUTTON:
                "dialog.set_state.confirmation_dialog.button.primary",
            CONFIRMATION_SECONDARY_BUTTON:
                "dialog.set_state.confirmation_dialog.button.secondary",
            CONFIRMATION_CURRENT:
                "dialog.set_state.confirmation_dialog.content.current",
            CONFIRMATION_HEADING:
                "dialog.set_state.confirmation_dialog.content.heading",
            CONFIRMATION_UNSAVED:
                "dialog.set_state.confirmation_dialog.content.unsaved",
            CONFIRMATION_TITLE: "dialog.set_state.confirmation_dialog.title",
            INPUT_ERROR: "dialog.set_state.input.error_message",
            ROOMS_INPUT_LABEL: "dialog.set_state.set_room.input.label",
            ROOMS_TITLE: "dialog.set_state.set_room.title"
        }
    },
    Configuration: {
        FLOORPLAN_UPLOAD_BUTTON: "configuration.floorplan_upload.button",
        CONTEXT_MENU_EDIT_ROOM: "configuration.context_menu.edit_room",
        CONTEXT_MENU_ADD_WAYPOINT: "configuration.context_menu.add_waypoint"
    },
    FloorPlan: {
        UPLOAD_BUTTON_PRIMARY: "dialog.floorplan_upload.button.primary",
        CONFIRMATION_PRIMARY_BUTTON:
            "dialog.floorplan_upload.confirmation_dialog.button.primary",
        CONFIRMATION_SECONDARY_BUTTON:
            "dialog.floorplan_upload.confirmation_dialog.button.secondary",
        CONFIRMATION_HEADING:
            "dialog.floorplan_upload.confirmation_dialog.content.heading",
        CONFIRMATION_UNSAVED:
            "dialog.floorplan_upload.confirmation_dialog.content.unsaved",
        CONFIRMATION_TITLE: "dialog.floorplan_upload.confirmation_dialog.title",
        INPUT_FILE_UPLOAD_ERROR:
            "dialog.floorplan_upload.content.input.upload.error_message",
        INPUT_NUMBER_LABEL: "dialog.floorplan_upload.content.input.label",
        INPUT_NUMBER_PLACEHOLDER:
            "dialog.floorplan_upload.content.input.placeholder",
        PREVIEW_TITLE: "dialog.floorplan_upload.content.preview.title",
        TITLE: "dialog.floorplan_upload.title",
        SCALE_FACTOR_START_POINT:
            "dialog.floorplan_upload.scale_factor.start_point",
        SCALE_FACTOR_END_POINT:
            "dialog.floorplan_upload.scale_factor.end_point",
        SCALE_FACTOR_INFO_GENERAL:
            "dialog.floorplan_upload.scale_factor.info_general",
        SCALE_FACTOR_INFO_START_POINT:
            "dialog.floorplan_upload.scale_factor.info_start_point",
        SCALE_FACTOR_INFO_END_POINT:
            "dialog.floorplan_upload.scale_factor.info_end_point",
        SCALE_FACTOR_RESET: "dialog.floorplan_upload.scale_factor.reset"
    },
    Notifications: {
        SAVE_CONFIGURATION_ERROR: "notifications.save.error",
        SAVE_CONFIGURATION_SUCCESS: "notifications.save.success"
    },
    Result: {
        CONTENT_EMPTY: "dialog.result.content.empty",
        CONTENT_NOT_ASSIGNED: "dialog.result.content.not_assigned",
        TABLE_HEADER_ITEM_NUMBER: "dialog.result.table.item_number",
        TABLE_HEADER_PRODUCT: "dialog.result.table.product",
        TABLE_HEADER_QUANTITY: "dialog.result.table.quantity",
        TAB_MANUFACTURERS: "dialog.result.tab.manufacturers",
        TAB_ROOMS: "dialog.result.tab.rooms",
        TITLE: "dialog.result.title"
    },
    Toolbar: {
        DISPLAY_CODE: "toolbar.display.code",
        BUTTON_CSV_EXPORT: "toolbar.button.csv_export",
        BUTTON_LOAD: "toolbar.button.load",
        BUTTON_RESULT: "toolbar.button.result",
        BUTTON_SAVE: "toolbar.button.save"
    }
}
