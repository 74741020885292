import {
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Stack,
    TextField
} from "@fluentui/react"
import { isEmpty, isNil } from "lodash"
import React, { useEffect, useState } from "react"

import { ParameterTO } from "@encoway/c-services-js-client"
import { ICommonProps } from "@encoway/cui-configurator-components/dist/esm/common.types"
import { L10n } from "@encoway/l10n"

import { TranslationConstants } from "../../../constants/translationConstants"
import { getTheme } from "../../../theme"
import { DefaultPrimaryButton } from "../../DefaultPrimaryButton/DefaultPrimaryButton"
import Dialog from "../../Dialog/Dialog"
import { FloorPlanUploadConfirmationDialog } from "./FloorPlanUploadConfirmationDialog"
import {
    Coordinates,
    EventTypes,
    FloorPlanUploadScaleFactor
} from "./FloorPlanUploadScaleFactor"
import { getBase64Image } from "./floorPlanUploadFileHelper"

export function FloorPlanUpload(props: Readonly<ICommonProps<ParameterTO>>) {
    const [showFloorPlanUpload, setShowFloorPlanUpload] =
        useState<boolean>(false)
    const [showConfirmationDialog, setShowConfirmationDialog] =
        useState<boolean>(false)
    const [fileName, setFileName] = useState<string>("")
    const [imageScaleFactor, setImageScaleFactor] = useState<number>(1)
    const [floorPlanWidth, setFloorPlanWidth] = useState<string>("")
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [base64Image, setBase64Image] = useState<string>("")
    const [startPointCoordinates, setStartPointCoordinates] =
        useState<Coordinates>()
    const [endPointCoordinates, setEndPointCoordinates] =
        useState<Coordinates>()
    const [resetScaleFactor, setResetScaleFactor] = useState<boolean>(false)

    const node = props.options?.visualizationNode

    useEffect(() => {
        setErrorMessage("")
        setBase64Image("")
    }, [fileName])

    const theme = getTheme()

    function determineDimensions(image: string) {
        return new Promise((resolve) => {
            const img = new window.Image()
            img.src = image
            img.onload = () => {
                resolve({
                    floorplan_real_width: img.width,
                    floorplan_real_height: img.height,
                    ...calculateFloorplanDimensions(img)
                })
            }
        })
    }

    function calculateFloorplanDimensions(img: HTMLImageElement) {
        if (startPointCoordinates && endPointCoordinates) {
            const fpWidth = Number(floorPlanWidth.replace(",", "."))
            const lineLength = endPointCoordinates.x - startPointCoordinates.x
            return {
                floorplan_width: img.width,
                floorplan_height: img.height,
                scaling_factor:
                    (fpWidth / (lineLength / imageScaleFactor)) * 1000
            }
        } else {
            return {
                floorplan_width: img.width,
                floorplan_height: img.height
            }
        }
    }

    async function onClickPrimary() {
        const dimensions = await determineDimensions(base64Image)
        // @ts-ignore
        node.state.setState({ floorplan_image: base64Image, ...dimensions })
        setShowFloorPlanUpload(false)
        setFileName("")
        setBase64Image("")
        setFloorPlanWidth("")
        setStartPointCoordinates(undefined)
        setEndPointCoordinates(undefined)
    }

    function onClickPrimaryConfirmationDialog() {
        setShowConfirmationDialog(false)
        setShowFloorPlanUpload(false)
    }

    const onClose = () => {
        if (!isEmpty(fileName)) {
            setShowConfirmationDialog(true)
        } else {
            setShowFloorPlanUpload(false)
        }
    }

    async function onChangeHandler(event: Event) {
        const target = event.target as HTMLInputElement
        if (isNil(target.files)) {
            setErrorMessage(
                L10n.format(
                    TranslationConstants.FloorPlan.INPUT_FILE_UPLOAD_ERROR
                )
            )
            return
        }
        if (target.files.length > 0) {
            const file = target.files[0]
            setFileName(file.name)
            const base64Image = await getBase64Image(file)
            setBase64Image(base64Image)
        }
    }

    async function scaleFactorEventHandler(eventName: EventTypes, data: any) {
        switch (eventName) {
            case "START_POINT_SET":
                setStartPointCoordinates(data)
                break
            case "END_POINT_SET":
                setEndPointCoordinates(data)
                break
            case "RESET_PROCESSED":
                setResetScaleFactor(false)
                break
            case "SET_IMAGE_SCALE_FACTOR":
                setImageScaleFactor(data)
                break
        }
    }

    return (
        <>
            <PrimaryButton
                onClick={() => setShowFloorPlanUpload(true)}
                text={L10n.format(
                    TranslationConstants.Configuration.FLOORPLAN_UPLOAD_BUTTON
                )}
                style={{ marginTop: "1.4rem" }}
            />
            {showFloorPlanUpload && (
                <Dialog
                    title={L10n.format(TranslationConstants.FloorPlan.TITLE)}
                    showPrimaryButton={
                        !isEmpty(base64Image) &&
                        !isEmpty(floorPlanWidth) &&
                        isEmpty(errorMessage)
                    }
                    showSecondaryButton={true}
                    primaryButtonTitle={L10n.format(
                        TranslationConstants.FloorPlan.UPLOAD_BUTTON_PRIMARY
                    )}
                    onClickPrimary={onClickPrimary}
                    onClickSecondary={onClose}
                    onClose={onClose}
                >
                    <TextField
                        styles={{
                            root: {
                                "input::file-selector-button": {
                                    backgroundColor: theme.palette.themePrimary,
                                    color: "white",
                                    padding: "0px 16px",
                                    border: "1px solid rgb(110, 200, 0)",
                                    height: "32px",
                                    cursor: "pointer"
                                }
                            },
                            fieldGroup: {
                                border: "none"
                            }
                        }}
                        type="file"
                        id="image"
                        accept="image/png,image/jpeg,application/pdf"
                        onChange={async (event) =>
                            onChangeHandler(event.nativeEvent)
                        }
                        errorMessage={errorMessage}
                    />
                    <br />
                    {!base64Image && (
                        <MessageBar
                            messageBarType={MessageBarType.warning}
                            isMultiline={false}
                        >
                            {L10n.format(
                                TranslationConstants.FloorPlan
                                    .SCALE_FACTOR_INFO_GENERAL
                            )}
                        </MessageBar>
                    )}
                    <Stack
                        horizontal
                        style={{
                            alignItems: "end"
                        }}
                    >
                        <Stack>
                            <Stack horizontal>
                                <Stack.Item align="start">
                                    <TextField
                                        type="number"
                                        disabled={!base64Image}
                                        label={L10n.format(
                                            TranslationConstants.FloorPlan
                                                .SCALE_FACTOR_START_POINT
                                        )}
                                        prefix="X"
                                        value={startPointCoordinates?.x.toString()}
                                        onChange={(e) =>
                                            startPointCoordinates &&
                                            setStartPointCoordinates({
                                                x: parseInt(
                                                    e.currentTarget.value
                                                ),
                                                y: startPointCoordinates.y
                                            })
                                        }
                                    />
                                    <TextField
                                        type="number"
                                        disabled={!base64Image}
                                        prefix="Y"
                                        value={startPointCoordinates?.y.toString()}
                                        onChange={(e) => {
                                            Number(e.currentTarget.value) > 0 &&
                                                startPointCoordinates &&
                                                setStartPointCoordinates({
                                                    x: startPointCoordinates.x,
                                                    y: parseInt(
                                                        e.currentTarget.value
                                                    )
                                                })
                                            endPointCoordinates &&
                                                setEndPointCoordinates({
                                                    x: endPointCoordinates.x,
                                                    y: parseInt(
                                                        e.currentTarget.value
                                                    )
                                                })
                                        }}
                                        style={{
                                            marginTop: "5px"
                                        }}
                                    />
                                </Stack.Item>
                                <Stack.Item
                                    align="end"
                                    style={{
                                        marginLeft: "15px"
                                    }}
                                >
                                    <TextField
                                        type="number"
                                        disabled={!base64Image}
                                        label={L10n.format(
                                            TranslationConstants.FloorPlan
                                                .SCALE_FACTOR_END_POINT
                                        )}
                                        prefix="X"
                                        value={endPointCoordinates?.x.toString()}
                                        onChange={(e) =>
                                            endPointCoordinates &&
                                            setEndPointCoordinates({
                                                x: parseInt(
                                                    e.currentTarget.value
                                                ),
                                                y: endPointCoordinates.y
                                            })
                                        }
                                    />
                                    <TextField
                                        type="number"
                                        disabled
                                        prefix="Y"
                                        value={endPointCoordinates?.y.toString()}
                                        style={{
                                            marginTop: "5px"
                                        }}
                                    />
                                </Stack.Item>
                            </Stack>
                            <TextField
                                disabled={!base64Image}
                                suffix="m"
                                type="number"
                                label={L10n.format(
                                    TranslationConstants.FloorPlan
                                        .INPUT_NUMBER_LABEL
                                )}
                                onChange={(event) => {
                                    setFloorPlanWidth(event.currentTarget.value)
                                }}
                                value={floorPlanWidth}
                                placeholder={L10n.format(
                                    TranslationConstants.FloorPlan
                                        .INPUT_NUMBER_PLACEHOLDER
                                )}
                            />
                        </Stack>
                        <DefaultPrimaryButton
                            disabled={!base64Image}
                            onClick={() => {
                                setStartPointCoordinates(undefined)
                                setEndPointCoordinates(undefined)
                                setResetScaleFactor(true)
                            }}
                            style={{
                                marginLeft: "15px",
                                bottom: "6.2rem"
                            }}
                        >
                            {L10n.format(
                                TranslationConstants.FloorPlan
                                    .SCALE_FACTOR_RESET
                            )}
                        </DefaultPrimaryButton>
                    </Stack>
                    <br />
                    {base64Image && !startPointCoordinates && (
                        <MessageBar
                            messageBarType={MessageBarType.warning}
                            isMultiline={false}
                        >
                            {L10n.format(
                                TranslationConstants.FloorPlan
                                    .SCALE_FACTOR_INFO_START_POINT
                            )}
                        </MessageBar>
                    )}
                    {base64Image &&
                        startPointCoordinates &&
                        !endPointCoordinates && (
                            <MessageBar
                                messageBarType={MessageBarType.warning}
                                isMultiline={false}
                            >
                                {L10n.format(
                                    TranslationConstants.FloorPlan
                                        .SCALE_FACTOR_INFO_END_POINT
                                )}
                            </MessageBar>
                        )}
                    {base64Image && (
                        <>
                            <div
                                style={{
                                    marginTop: "1rem",
                                    fontSize: "1.25rem",
                                    fontWeight: 600
                                }}
                            >
                                {L10n.format(
                                    TranslationConstants.FloorPlan.PREVIEW_TITLE
                                )}
                            </div>
                            <FloorPlanUploadScaleFactor
                                image={base64Image}
                                eventHandler={scaleFactorEventHandler}
                                reset={resetScaleFactor}
                                manualCoordinates={{
                                    start: startPointCoordinates,
                                    end: endPointCoordinates
                                }}
                            />
                        </>
                    )}
                    {showConfirmationDialog && (
                        <FloorPlanUploadConfirmationDialog
                            fileName={fileName}
                            onClickPrimaryHandler={
                                onClickPrimaryConfirmationDialog
                            }
                            onClickSecondaryHandler={() =>
                                setShowConfirmationDialog(false)
                            }
                        />
                    )}
                </Dialog>
            )}
        </>
    )
}
