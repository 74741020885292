import { TextField } from "@fluentui/react"
import axios from "axios"
import { isEmpty, isNil } from "lodash"
import React, { useState } from "react"

import { L10n } from "@encoway/l10n"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
    selectSavedConfigurationCode,
    selectVisualization,
    setSavedConfigurationCode,
    setVisualizationChanged
} from "../../app/visualizationSlice"
import { TranslationConstants } from "../../constants/translationConstants"
import Dialog from "../Dialog/Dialog"

export function LoadConfigurationDialog(props: any) {
    const [value, setValue] = useState<string>("")
    const [error, setError] = useState<string | undefined>(undefined)
    const savedConfigurationCode = useAppSelector(selectSavedConfigurationCode)
    const dispatch = useAppDispatch()
    const visualization = useAppSelector(selectVisualization)

    const onClose = () => {
        props.onCloseDialog()
    }

    const loadConfiguration = (configurationData: any) => {
        visualization
            .loadState(JSON.parse(configurationData).visualizationData)
            .then(() => {
                dispatch(setSavedConfigurationCode(value))
                dispatch(setVisualizationChanged(true))
                onClose()
            })
            .catch(() =>
                setError(
                    L10n.format(
                        TranslationConstants.Dialogs.LoadConfiguration
                            .INPUT_ERROR
                    )
                )
            )
    }

    const onChangeHandler = (value: string) => {
        setError("")
        setValue(value)
        if (value === savedConfigurationCode) {
            setError(
                L10n.format(
                    TranslationConstants.Dialogs.LoadConfiguration
                        .INPUT_ERROR_LOADED
                )
            )
        }
    }

    const loadConfigurationCode = (configurationCode: string) => {
        axios
            .get(`api/configuration/configurations/code/${configurationCode}`)
            .then((response) => {
                if (response.status === 200) {
                    loadConfiguration(response.data)
                }
            })
            .catch(() =>
                setError(
                    L10n.format(
                        TranslationConstants.Dialogs.LoadConfiguration
                            .INPUT_ERROR_INVALID
                    )
                )
            )
    }

    const onClickPrimary = () => {
        loadConfigurationCode(value)
    }

    const isLoadButtonActive =
        !isNil(value) && !isEmpty(value) && value.length === 8
    return (
        <Dialog
            title={L10n.format(
                TranslationConstants.Dialogs.LoadConfiguration.TITLE
            )}
            showPrimaryButton={isLoadButtonActive}
            showSecondaryButton={true}
            primaryButtonTitle={L10n.format(
                TranslationConstants.Dialogs.LoadConfiguration.PRIMARY_BUTTON
            )}
            onClickPrimary={onClickPrimary}
            onClickSecondary={onClose}
            onClose={onClose}
        >
            <TextField
                onChange={(event) => onChangeHandler(event.currentTarget.value)}
                label={L10n.format(
                    TranslationConstants.Dialogs.LoadConfiguration.INPUT_LABEL
                )}
                errorMessage={error}
                aria-required={true}
                required={true}
                value={value}
                placeholder={L10n.format(
                    TranslationConstants.Dialogs.LoadConfiguration
                        .INPUT_PLACEHOLDER
                )}
            />
        </Dialog>
    )
}
