import { TextField } from "@fluentui/react"
import { isEmpty, isNil } from "lodash"
import React, { useState } from "react"

import { L10n } from "@encoway/l10n"

import { TranslationConstants } from "../constants/translationConstants"
import Dialog from "./Dialog/Dialog"

interface SetNodeStateValueModalProps {
    setShowDialog: React.Dispatch<React.SetStateAction<boolean>>
    node: any
    dialogTitle: string
    textFieldLabel: string
    type: "room" | "manufacturer"
}

export function VisualizationSetStateDialog(
    props: Readonly<SetNodeStateValueModalProps>
) {
    const [initialStateValue] = useState<string>(
        props.node.state.state[props.type] ?? ""
    )
    const [stateValue, setStateValue] = useState<string>(initialStateValue)
    const [error, setError] = useState<string | undefined>(undefined)
    const [showConfirmationDialog, setShowConfirmationDialog] =
        useState<boolean>(false)

    const onClickPrimary = () => {
        if (isNil(stateValue) || isEmpty(stateValue)) {
            setError(
                L10n.format(TranslationConstants.Dialogs.State.INPUT_ERROR, {
                    input_label: props.textFieldLabel
                })
            )
        } else {
            props.node.state.setState({ [props.type]: stateValue })
            props.setShowDialog(false)
        }
    }

    const onClickPrimaryConfirmationDialog = () => {
        setShowConfirmationDialog(false)
        props.setShowDialog(false)
    }

    const onClose = () => {
        if (
            stateValue !== initialStateValue &&
            stateValue !== "" &&
            initialStateValue !== ""
        ) {
            setShowConfirmationDialog(true)
        } else {
            props.setShowDialog(false)
        }
    }

    const onClickCancel = () => {
        onClose()
    }

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            onClickPrimary()
        }
    }

    return (
        <Dialog
            title={props.dialogTitle}
            showPrimaryButton={true}
            showSecondaryButton={true}
            onClickPrimary={onClickPrimary}
            onClickSecondary={onClickCancel}
            onClose={onClose}
        >
            <TextField
                onChange={(event) => {
                    setStateValue(event.currentTarget.value)
                }}
                onKeyDown={onKeyDown}
                label={props.textFieldLabel}
                errorMessage={error}
                aria-required={true}
                required={true}
                value={stateValue}
                placeholder={props.textFieldLabel}
            />

            {showConfirmationDialog && (
                <Dialog
                    title={L10n.format(
                        TranslationConstants.Dialogs.State.CONFIRMATION_TITLE
                    )}
                    showPrimaryButton={true}
                    showSecondaryButton={true}
                    primaryButtonTitle={L10n.format(
                        TranslationConstants.Dialogs.State
                            .CONFIRMATION_PRIMARY_BUTTON
                    )}
                    secondaryButtonTitle={L10n.format(
                        TranslationConstants.Dialogs.State
                            .CONFIRMATION_SECONDARY_BUTTON
                    )}
                    onClickSecondary={() => {
                        setShowConfirmationDialog(false)
                    }}
                    onClickPrimary={() => {
                        onClickPrimaryConfirmationDialog()
                    }}
                >
                    <div>
                        {L10n.format(
                            TranslationConstants.Dialogs.State
                                .CONFIRMATION_HEADING
                        )}
                    </div>
                    <br />
                    <div>
                        {L10n.format(
                            TranslationConstants.Dialogs.State
                                .CONFIRMATION_CURRENT
                        )}{" "}
                        {props.node.state.state[props.type]}
                    </div>
                    <div>
                        {L10n.format(
                            TranslationConstants.Dialogs.State
                                .CONFIRMATION_UNSAVED
                        )}{" "}
                        {stateValue}
                    </div>
                </Dialog>
            )}
        </Dialog>
    )
}
