import { initializeIcons, loadTheme } from "@fluentui/react"
import { merge } from "lodash"
import React, { useCallback, useEffect, useRef, useState } from "react"

import {
    ThemedApplication,
    translations as tconf
} from "@encoway/cui-application-components"
import { translations as tapp } from "@encoway/cui-configurator-components"
import { L10n } from "@encoway/l10n"

import { useAppDispatch, useAppSelector } from "../app/hooks"
import {
    selectVisualization,
    setVisualization,
    setVisualizationChanged
} from "../app/visualizationSlice"
import { TranslationConstants } from "../constants/translationConstants"
import { getSettings } from "../settings"
import { getTheme } from "../theme"
import { VisualizationSetStateDialog } from "./VisualizationSetStateDialog"

interface AppProps {
    productId: string
}

export function Configurator({ productId }: Readonly<AppProps>) {
    const [showDialog, setShowDialog] = useState<boolean>(false)
    const [visuNode, setVisuNode] = useState<any>(undefined)
    const visualizationIsLoading = useRef<boolean>(false)

    const dispatch = useAppDispatch()
    const visualization = useAppSelector(selectVisualization)

    const visualizationRef = useCallback((node) => {
        initializeIcons()
        if (node?.props?.visualization) {
            dispatch(setVisualization(node.props.visualization))
        }
    }, [])

    useEffect(() => {
        if (visualization) {
            const unsubscribe = visualization.cloud
                .graph()
                .eventBus()
                .onValue((e: { event: string; node: any }) => {
                    dispatch(setVisualizationChanged(true))
                    if (e.event === "BeginRestore") {
                        visualizationIsLoading.current = true
                    } else if (e.event === "EndRestore") {
                        visualizationIsLoading.current = false
                    }

                    if (
                        e.event === "NodeMounted" &&
                        !visualizationIsLoading.current
                    ) {
                        setVisuNode(e.node)
                        if (
                            e.node.state.state.element_type &&
                            e.node.state.state.element_type === "box"
                        ) {
                            setShowDialog(true)
                        }
                    }
                })

            return () => unsubscribe()
        }
    }, [visualization])

    return (
        <>
            {showDialog && (
                <VisualizationSetStateDialog
                    setShowDialog={setShowDialog}
                    dialogTitle={L10n.format(
                        TranslationConstants.Dialogs.State.ROOMS_TITLE
                    )}
                    textFieldLabel={L10n.format(
                        TranslationConstants.Dialogs.State.ROOMS_INPUT_LABEL
                    )}
                    node={visuNode}
                    type="room"
                />
            )}
            <ThemedApplication
                theme={loadTheme(getTheme())}
                translations={merge({}, tapp, tconf)}
                appSettings={getSettings(productId, L10n.currentFullLocale())}
                ref={visualizationRef}
            />
        </>
    )
}
