import { isEmpty } from "lodash"

import { Product } from "@encoway/c-services-js-client"
import { Constants, Visualization } from "@encoway/visual-editor"

export const buildStructuredBom = async (visualization: Visualization) => {
    const result = await visualization.result(Constants.BOM.Tree)
    // @ts-ignore
    const nodes: object[] | undefined = result.state.nodes
    if (nodes && !isEmpty(nodes)) {
        return nodes.reduce((structure: object[], node: any) => {
            if (node.state.excludeFromBOM) {
                return [...structure]
            }
            return [...structure, prepareNode(node, visualization)]
        }, [])
    }
    return []
}

const prepareNode = (node: any, visualization: Visualization) => {
    const visuNode = visualization.cloud.find(node.id)
    // @ts-ignore
    const nodeItem: Product = visuNode._props.product.product

    return {
        ...node,
        nodeProperties: nodeItem,
        quantity: 1
    }
}
