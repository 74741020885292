import {MediaValue, NumberValue, valueOf} from "@encoway/c-services-js-client"

export const getCharacteristicValue = (data: any, characteristic: string): string => {
    const value = valueOf(data, characteristic)

    if (!value) {
        return ""
    } else if (isArray(value)) {
        return value[0].toString()
    } else {
        return getValueByType(value)
    }
}

const getValueByType = (value: string | NumberValue | MediaValue | null): string => {
    if (isNumberValue(value)) {
        return value.value.toString()
    } else if (isMediaValue(value)) {
        return value.uri
    } else {
        return value ?? ""
    }
}

type ValueOfType = string | string[] | NumberValue | MediaValue | NumberValue[] | MediaValue[] | null

const isArray = (value: ValueOfType): value is string[] | NumberValue[] | MediaValue[] => {
    return Array.isArray(value)
}

export const isNumberValue = (value: ValueOfType): value is NumberValue => {
    return (<NumberValue>value).value !== undefined
}

export const isMediaValue = (value: ValueOfType): value is MediaValue => {
    return (<MediaValue>value).uri !== undefined
}