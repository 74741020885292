import { Stack } from "@fluentui/react"
import React, { MutableRefObject, useEffect, useRef, useState } from "react"

import { getTheme } from "../../../theme"

export type Coordinates = {
    x: number
    y: number
}

export type EventTypes =
    | "START_POINT_SET"
    | "END_POINT_SET"
    | "RESET_PROCESSED"
    | "SET_IMAGE_SCALE_FACTOR"

type Props = {
    image: string
    eventHandler: (eventName: EventTypes, data: any) => Promise<void>
    reset: boolean
    manualCoordinates: {
        start?: Coordinates
        end?: Coordinates
    }
}

const PREVIEW_SIZE = 100
const CROSS_HAIR_SIZE = 40
const MAX_CANVAS_SIZE = 600

export const FloorPlanUploadScaleFactor = (props: Props) => {
    const canvasRef = useRef(null)
    const canvasPreviewRef = useRef(null)
    const startPointSet = useRef<boolean>(false)
    const startPointCoordinates = useRef<Coordinates>({ x: -1, y: -1 })
    const endPointSet = useRef<boolean>(false)
    const endPointCoordinates = useRef<Coordinates>({ x: -1, y: -1 })

    const [image, setImage] = useState<HTMLImageElement>()
    const [canvasWidth, setCanvasWidth] = useState<number>(0)
    const [canvasHeight, setCanvasHeight] = useState<number>(0)
    const [canvasPreview, setCanvasPreview] = useState<ImageData>()

    const theme = getTheme()

    useEffect(() => {
        const img = new window.Image()
        img.src = props.image
        img.onload = () => {
            let scaleFactor = 1
            if (img.width > img.height && img.width > MAX_CANVAS_SIZE) {
                scaleFactor = MAX_CANVAS_SIZE / img.width
            } else if (img.height > img.width && img.height > MAX_CANVAS_SIZE) {
                scaleFactor = MAX_CANVAS_SIZE / img.height
            }
            setCanvasWidth(img.width * scaleFactor)
            setCanvasHeight(img.height * scaleFactor)
            setImage(img)
            props.eventHandler("SET_IMAGE_SCALE_FACTOR", scaleFactor)
        }
    }, [])

    useEffect(() => {
        if (props.reset) {
            startPointSet.current = false
            endPointSet.current = false
            startPointCoordinates.current = { x: -1, y: -1 }
            endPointCoordinates.current = { x: -1, y: -1 }
            initCanvas()
            props.eventHandler("RESET_PROCESSED", true)
        }
    }, [props.reset])

    useEffect(() => {
        if (props.manualCoordinates) {
            if (props.manualCoordinates.start) {
                startPointCoordinates.current = props.manualCoordinates.start
            }
            if (props.manualCoordinates.end) {
                endPointCoordinates.current = props.manualCoordinates.end
            }
            drawScaleFactorElements()
        }
    }, [props.manualCoordinates])

    useEffect(() => {
        const canvas = getCanvas(canvasRef)
        const context = getCanvasContext(canvasRef)
        if (canvas && image) {
            if (context) {
                context.drawImage(image, 0, 0, canvasWidth, canvasHeight)
            }

            canvas.removeEventListener("click", handleCanvasClick)
            canvas.addEventListener("click", handleCanvasClick)
            canvas.removeEventListener("mousemove", handleCanvasMove)
            canvas.addEventListener("mousemove", handleCanvasMove)
        }
    }, [canvasRef.current, image, canvasWidth, canvasHeight])

    useEffect(() => {
        if (canvasPreviewRef.current && canvasPreview) {
            const canvas: HTMLCanvasElement = canvasPreviewRef.current

            const context: CanvasRenderingContext2D | null =
                canvas.getContext("2d")
            if (context) {
                const renderer = document.createElement("canvas")
                renderer.width = canvasPreview.width
                renderer.height = canvasPreview.height
                // render our ImageData on this canvas
                // @ts-ignore
                renderer.getContext("2d").putImageData(canvasPreview, 0, 0)
                context.drawImage(
                    renderer,
                    0,
                    0,
                    PREVIEW_SIZE * 2,
                    PREVIEW_SIZE * 2
                )
            }
        }
    }, [canvasPreview])

    const handleCanvasClick = (e: MouseEvent) => {
        if (!startPointSet.current) {
            startPointSet.current = true
            startPointCoordinates.current = {
                x: e.offsetX,
                y: e.offsetY
            }
            props.eventHandler("START_POINT_SET", {
                x: e.offsetX,
                y: e.offsetY
            })
        } else if (
            !endPointSet.current &&
            e.offsetX > startPointCoordinates.current.x
        ) {
            endPointSet.current = true
            props.eventHandler("END_POINT_SET", {
                x: e.offsetX,
                y: startPointCoordinates.current.y
            })
        }
    }

    const handleCanvasMove = (e: MouseEvent) => {
        const canvas = getCanvas(canvasRef)
        const context = getCanvasContext(canvasRef)
        if (
            startPointSet.current &&
            !endPointSet.current &&
            e.offsetX > startPointCoordinates.current.x
        ) {
            if (canvas && context) {
                const startY = startPointCoordinates.current.y
                const endX = e.offsetX

                drawScaleFactorElements(endX)
                determinePreviewImage({ x: endX, y: startY })
            }
        } else if (!startPointSet.current) {
            initCanvas()
            drawCrossHair({ x: e.offsetX, y: e.offsetY })
            determinePreviewImage({ x: e.offsetX, y: e.offsetY })
        }
    }

    const drawCrossHair = (coordinates: Coordinates) => {
        const context = getCanvasContext(canvasRef)
        if (context) {
            const x = coordinates.x
            const y = coordinates.y
            const crossHairRadius = CROSS_HAIR_SIZE / 2
            const crossHairMargin = crossHairRadius / 5

            context.beginPath()
            context.lineWidth = 2

            // Circle
            context.arc(x, y, crossHairRadius, 0, 2 * Math.PI)
            // Inner line top
            context.moveTo(x, y - crossHairRadius)
            context.lineTo(x, y - crossHairMargin)
            // Inner line bottom
            context.moveTo(x, y + crossHairMargin)
            context.lineTo(x, y + crossHairRadius)
            // Inner line left
            context.moveTo(x - crossHairRadius, y)
            context.lineTo(x - crossHairMargin, y)
            // Inner line right
            context.moveTo(x + crossHairMargin, y)
            context.lineTo(x + crossHairRadius, y)

            context.closePath()
            context.stroke()
        }
    }

    const drawScaleFactorElements = (xEndPos?: number) => {
        const context = getCanvasContext(canvasRef)
        if (context) {
            const startX = startPointCoordinates.current.x
            const startY = startPointCoordinates.current.y
            const endX = xEndPos ?? endPointCoordinates.current.x

            if (startX < 0 || startY < 0 || endX < 0) {
                return
            }

            initCanvas()

            // Cross-hair for start coordinates
            drawCrossHair({ x: startX, y: startY })
            // Cross-hair for end coordinates
            drawCrossHair({ x: endX, y: startY })

            context.lineWidth = 4
            context.beginPath()
            context.moveTo(startX + 20, startY)
            context.lineTo(endX - 20, startY)
            context.stroke()
        }
    }

    const determinePreviewImage = (coordinates: Coordinates) => {
        const context = getCanvasContext(canvasRef)
        if (context) {
            setCanvasPreview(
                context.getImageData(
                    coordinates.x - PREVIEW_SIZE / 2,
                    coordinates.y - PREVIEW_SIZE / 2,
                    PREVIEW_SIZE,
                    PREVIEW_SIZE
                )
            )
        }
    }

    const initCanvas = () => {
        const canvas = getCanvas(canvasRef)
        const context = getCanvasContext(canvasRef)
        if (canvas && context && image) {
            context.clearRect(0, 0, canvas.width, canvas.height)
            context.drawImage(image, 0, 0, canvasWidth, canvasHeight)
            context.strokeStyle = theme.palette.themePrimary
        }
    }

    const getCanvas = (canvasRef: MutableRefObject<any>) => {
        if (canvasRef.current) {
            return canvasRef.current
        }
        return null
    }
    const getCanvasContext = (canvasRef: MutableRefObject<any>) => {
        const canvas = getCanvas(canvasRef)
        if (canvas) {
            const context: CanvasRenderingContext2D | null =
                canvas.getContext("2d")
            if (context) {
                return context
            }
        }
        return null
    }

    return (
        <>
            <br />
            <Stack horizontal>
                <Stack.Item>
                    <canvas
                        ref={canvasRef}
                        width={canvasWidth}
                        height={canvasHeight}
                        style={{
                            border: "1px solid"
                        }}
                    />
                </Stack.Item>
                <Stack.Item>
                    <canvas
                        ref={canvasPreviewRef}
                        width={PREVIEW_SIZE * 2}
                        height={PREVIEW_SIZE * 2}
                        style={{
                            border: "1px solid",
                            marginLeft: "10px"
                        }}
                    />
                </Stack.Item>
            </Stack>
        </>
    )
}
