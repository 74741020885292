import { L10n } from "@encoway/l10n"

import { getCatalogService } from "../app/catalogServiceHolder"

const FALLBACK_LANGUAGE = "en-US"

export async function initializeLanguage() {
    const lang = await determineLocale()
    L10n.currentLocale(lang)
    getCatalogService().useLanguage(lang)
}

async function determineLocale(): Promise<string> {
    const browserLanguage: string = navigator.language

    if (browserLanguage.includes("-") || browserLanguage.includes("_")) {
        browserLanguage.replace("_", "-")
        return browserLanguage
    } else {
        const catalogService = getCatalogService()
        const response = await catalogService.languages()

        // @ts-ignore
        const element = response.languages.find((lang) => {
            return lang.tag.startsWith(browserLanguage) ? lang : undefined
        })

        if (element === undefined) {
            return FALLBACK_LANGUAGE
        } else {
            element.tag.replace("_", "-")
            return element.tag
        }
    }
}
