import {
    Characteristics,
    Filter,
    Product,
    Selection
} from "@encoway/c-services-js-client"

import { getCatalogService } from "../../app/catalogServiceHolder"
import {
    CABLE_COLOR_CHARACTERISTIC,
    CABLE_LENGTH_CHARACTERISTIC
} from "../../constants/cableConstants"
import { getCharacteristicValue } from "../../utils/characteristicValueService"

export const determineCableProducts = async () => {
    const catalogService = getCatalogService()

    const productGroupFilter = Filter.productGroupFilter()
        .id("cable")
        .includeSubgroups(false)
    const selectionQuery = new Selection()
        .limit(1000)
        .filter(productGroupFilter)
        .characteristics(
            new Characteristics()
                .id("product_image")
                .id("manufacturer")
                .id("cable_length")
                .id("colour_connectors")
        )

    const cableProducts = new Map<string, Map<number, Product>>()
    const result = await catalogService.products(selectionQuery)
    result.products.forEach((product) => {
        const cableLength = Number.parseFloat(
            getCharacteristicValue(product, CABLE_LENGTH_CHARACTERISTIC)
        )
        const cableColor = getCharacteristicValue(
            product,
            CABLE_COLOR_CHARACTERISTIC
        )
        if (cableLength && cableColor) {
            if (!cableProducts.has(cableColor)) {
                cableProducts.set(cableColor, new Map<number, Product>())
            }
            cableProducts.get(cableColor)?.set(cableLength, product)
        }
    })
    return cableProducts
}
