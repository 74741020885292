import { CatalogService, Http } from "@encoway/c-services-js-client"

let catalogService: CatalogService | null = null

export const initCatalogService = async () => {
    const http = Http.Plain()
    catalogService = new CatalogService(http, "", "")
}

export const getCatalogService = () => {
    if (!catalogService) {
        throw new Error("catalogService is not initialized")
    }
    return catalogService
}
