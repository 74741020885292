import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { Product } from "@encoway/c-services-js-client"

import { RootState } from "../../app/store"

export type ResultBomProduct = {
    id: string
    name: string
    quantity: number
    productImage: string
    room: string
    manufacturer: string
    cableLength: string
    additionalInformation?: string
}

export interface BomSliceState {
    resultBom: ResultBomProduct[]
    cableProducts: Map<string, Map<number, Product>>
}

const initialState: BomSliceState = {
    resultBom: [],
    cableProducts: new Map<string, Map<number, Product>>()
}

export const bomSlice = createSlice({
    name: "bom",
    initialState,
    reducers: {
        setResultBom: (
            state,
            action: PayloadAction<BomSliceState["resultBom"]>
        ): void => {
            state.resultBom = action.payload
        },
        setCableProducts: (
            state,
            action: PayloadAction<Map<string, Map<number, Product>>>
        ): void => {
            state.cableProducts = action.payload
        }
    }
})

export const { setResultBom, setCableProducts } = bomSlice.actions

export default bomSlice.reducer

export const selectResultBom = (state: RootState) => state.bom.resultBom
export const selectCableProducts = (state: RootState) => state.bom.cableProducts
