import { isEmpty } from "lodash"

import { L10n } from "@encoway/l10n"

import { TranslationConstants } from "../../constants/translationConstants"
import { ResultBomProduct } from "./bomSlice"

export type StructuredResultBom = { [key: string]: ResultBomProduct[] }

export const structureResultBomBy = (
    resultBom: ResultBomProduct[],
    by: "room" | "manufacturer",
    groupSameProducts?: boolean
): StructuredResultBom => {
    const sortedResultBom = sortResultBom(resultBom, by)
    return groupResultBom(sortedResultBom, by, groupSameProducts)
}

const sortResultBom = (
    resultBom: ResultBomProduct[],
    by: "room" | "manufacturer"
): ResultBomProduct[] =>
    resultBom.slice().sort((a, b) => {
        if (isEmpty(a[by])) {
            return 1
        } else if (a[by] === b[by]) {
            return 0
        } else {
            return !isEmpty(b[by]) && a[by] > b[by] ? 1 : -1
        }
    })

const groupResultBom = (
    resultBom: ResultBomProduct[],
    by: "room" | "manufacturer",
    groupSameProducts?: boolean
): StructuredResultBom => {
    const NAName = L10n.format(TranslationConstants.Result.CONTENT_NOT_ASSIGNED)
    const handledProducts: string[] = []

    return resultBom.reduce((grouped: StructuredResultBom, item) => {
        const groupKey = item[by] || NAName
        const productKey = `${groupKey}_${item.id}`

        if (groupSameProducts && handledProducts.includes(productKey)) {
            const productIndex = grouped[groupKey].findIndex(
                (product) => product.id === item.id
            )
            const product = grouped[groupKey][productIndex]
            grouped[groupKey][productIndex] = {
                ...product,
                quantity: product.quantity + 1
            }
            return grouped
        }

        handledProducts.push(productKey)
        return {
            ...grouped,
            ...{ [groupKey]: [...(grouped[groupKey] || []), item] }
        }
    }, {})
}
