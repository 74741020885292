import { configureStore } from "@reduxjs/toolkit"

import bomReducer from "../features/bom/bomSlice"
import nodeReducer from "./nodeSlice"
import toastReducer from "./toastSlice"
import visualizationReducer from "./visualizationSlice"

export const store = configureStore({
    reducer: {
        bom: bomReducer,
        visualization: visualizationReducer,
        node: nodeReducer,
        toasts: toastReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // We must ignore some actions and slice states, because the types are not serializable and throws an error
                ignoredActions: [
                    "bom/setResultBom",
                    "bom/setCableProducts",
                    "node/setNode",
                    "visualization/setVisualization"
                ],
                ignoredPaths: [
                    "bom.resultBom",
                    "bom.cableProducts",
                    "node.node",
                    "visualization.visualization"
                ]
            }
        })
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
