import React from "react"
import { Link, useLocation } from "react-router-dom"

import { L10n } from "@encoway/l10n"

import { TranslationConstants } from "../../constants/translationConstants"
import CsvExportButton from "../CsvExportButton"
import { DefaultPrimaryButton } from "../DefaultPrimaryButton/DefaultPrimaryButton"
import LoadConfigurationButton from "../LoadConfiguration/LoadConfigurationButton"
import SaveConfigurationButton from "../SaveConfigurationButton"
import SavedConfigurationCodeDisplay from "./SavedConfigurationCodeDisplay"

export default function ActionToolbar() {
    const location = useLocation()

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                padding: "1rem",
                justifyContent: "right"
            }}
        >
            <Link to="/result/rooms" state={{ backgroundLocation: location }}>
                <DefaultPrimaryButton>
                    {L10n.format(TranslationConstants.Toolbar.BUTTON_RESULT)}
                </DefaultPrimaryButton>
            </Link>
            <CsvExportButton />
            <SaveConfigurationButton />
            <LoadConfigurationButton />
            <SavedConfigurationCodeDisplay />
        </div>
    )
}
