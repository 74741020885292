import React from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

import { L10n } from "@encoway/l10n"

import { TranslationConstants } from "../../constants/translationConstants"
import Dialog from "../Dialog/Dialog"
import Navigation, { LinkPropsAdvanced } from "../Navigation/Navigation"

export function VisualizationResultDialog() {
    const navigate = useNavigate()
    const location = useLocation()

    const links: LinkPropsAdvanced[] = [
        {
            title: L10n.format(TranslationConstants.Result.TAB_ROOMS),
            to: "rooms",
            relative: "path",
            state: {
                backgroundLocation: location.state.backgroundLocation
            }
        },
        {
            title: L10n.format(TranslationConstants.Result.TAB_MANUFACTURERS),
            to: "manufacturers",
            relative: "path",
            state: {
                backgroundLocation: location.state.backgroundLocation
            }
        }
    ]

    const onClose = () => {
        navigate("/")
    }

    return (
        <Dialog
            title={L10n.format(TranslationConstants.Result.TITLE)}
            showPrimaryButton={false}
            showSecondaryButton={false}
            onClose={onClose}
        >
            <Navigation links={links} />
            <Outlet />
        </Dialog>
    )
}
