import { SelectionMode } from "@fluentui/react"
import React, { useEffect, useState } from "react"

import { L10n } from "@encoway/l10n"

import { TranslationConstants } from "../../constants/translationConstants"
import { ResultBomProduct } from "../../features/bom/bomSlice"
import {
    StructuredResultBom,
    structureResultBomBy
} from "../../features/bom/structureResultBomService"
import { useDetermineResultBom } from "../../features/bom/useDetermineResultBom"
import ResultBomTable from "./ResultBomTable"

type Props = {
    type: "room" | "manufacturer"
}
const styles = {
    p: {
        marginBlockEnd: "0",
        fontWeight: "600"
    }
}

export default function ResultBom(props: Readonly<Props>) {
    const { determineResultBom } = useDetermineResultBom()
    const [structuredResultBom, setStructuredResultBom] =
        useState<StructuredResultBom>({})

    useEffect(() => {
        determineResultBom().then((resultBom) =>
            setStructuredResultBom(
                structureResultBomBy(resultBom, props.type, true)
            )
        )
    }, [props.type])

    function renderResultBomGroup(
        groupName: string,
        groupItems: ResultBomProduct[]
    ) {
        if (!groupItems) {
            return
        }
        return (
            <div key={`result-bom-group-${groupName}`}>
                <p style={styles.p}>{groupName}</p>
                <hr />
                <ResultBomTable
                    items={groupItems}
                    selectionMode={SelectionMode.none}
                />
            </div>
        )
    }

    const structuredResultBomNotEmpty =
        Object.keys(structuredResultBom).length > 0
    return (
        <div style={{ minWidth: "40vw", maxHeight: "80vh", overflow: "auto" }}>
            {structuredResultBomNotEmpty &&
                Object.keys(structuredResultBom).map((groupName) =>
                    renderResultBomGroup(
                        groupName,
                        structuredResultBom[groupName]
                    )
                )}
            {!structuredResultBomNotEmpty && (
                <p style={styles.p}>
                    {L10n.format(TranslationConstants.Result.CONTENT_EMPTY)}
                </p>
            )}
        </div>
    )
}
