import { Product } from "@encoway/c-services-js-client"
import { L10n } from "@encoway/l10n"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
    hasVisualizationChanged,
    selectVisualization
} from "../../app/visualizationSlice"
import { CABLE_COLOR_CHARACTERISTIC } from "../../constants/cableConstants"
import { TranslationConstants } from "../../constants/translationConstants"
import { getCharacteristicValue } from "../../utils/characteristicValueService"
import {
    BomSliceState,
    ResultBomProduct,
    selectCableProducts,
    selectResultBom,
    setCableProducts,
    setResultBom
} from "./bomSlice"
import { determineCableProducts } from "./determineCableProductsService"
import { buildStructuredBom } from "./structuredBomService"

let cableCount = 0

export const useDetermineResultBom = () => {
    const visualizationChanged = useAppSelector(hasVisualizationChanged)
    const visualization = useAppSelector(selectVisualization)
    const resultBom = useAppSelector(selectResultBom)
    const cables = useAppSelector(selectCableProducts)
    const dispatch = useAppDispatch()

    const determineResultBom = async () => {
        if (visualizationChanged && visualization) {
            const structuredBom = await buildStructuredBom(visualization)
            let cableProducts = cables
            if (!cables.size) {
                cableProducts = await determineCableProducts()
                dispatch(setCableProducts(cableProducts))
            }
            const generatedResultBom = buildResultBom(
                structuredBom,
                cableProducts
            )
            dispatch(setResultBom(generatedResultBom))
            return generatedResultBom
        }
        return Promise.resolve(resultBom)
    }

    return { determineResultBom }
}

const buildResultBom = (
    structuredBom: object[],
    cables: Map<string, Map<number, Product>>
) => {
    const generatedResultBom: ResultBomProduct[] = []
    cableCount = 0
    structuredBom.forEach((node) => {
        const cableColor = getCharacteristicValue(
            // @ts-ignore
            node.nodeProperties,
            CABLE_COLOR_CHARACTERISTIC
        ) as string

        if (cableColor) {
            cableCount += 1
            generatedResultBom.push(...splitCable(cables, cableColor, node))
        } else {
            generatedResultBom.push(createResultBomProduct(node))
        }
    })
    return generatedResultBom
}

const splitCable = (
    cables: BomSliceState["cableProducts"],
    cableColor: string,
    node: any
) => {
    const resultBomProducts: ResultBomProduct[] = []
    const coloredCableProducts =
        cables.get(cableColor) || new Map<number, Product>()
    const cableLengths = [
        // @ts-ignore
        ...coloredCableProducts.keys()
    ].sort()
    if (!cableLengths.length) {
        return resultBomProducts
    }

    const longestCableLength = cableLengths[cableLengths.length - 1]
    const longestCableProduct = coloredCableProducts.get(
        longestCableLength
    ) as Product
    let calculatedCableLength = getCableLength(node, true)
    if (calculatedCableLength > longestCableLength) {
        calculatedCableLength = addBomProductsForLongestCable(
            resultBomProducts,
            calculatedCableLength,
            longestCableLength,
            longestCableProduct,
            node
        )
    }
    for (const cableLength of cableLengths) {
        if (calculatedCableLength < cableLength) {
            resultBomProducts.push(
                createResultBomProduct({
                    ...(coloredCableProducts.get(cableLength) as Product),
                    node
                })
            )
            calculatedCableLength -= cableLength
            break
        }
    }

    return resultBomProducts
}

const addBomProductsForLongestCable = (
    resultBomProducts: ResultBomProduct[],
    cableLength: number,
    longestCableLength: number,
    productToAdd: Product,
    node: any
) => {
    let calculatedCableLength = cableLength
    while (calculatedCableLength > longestCableLength) {
        resultBomProducts.push(
            createResultBomProduct({
                ...productToAdd,
                node
            })
        )
        calculatedCableLength -= longestCableLength
    }
    return calculatedCableLength
}

const createResultBomProduct = (node: any): ResultBomProduct => {
    if ("nodeProperties" in node) {
        return {
            id: node.nodeProperties.id,
            name: node.nodeProperties.name || "",
            quantity: node.quantity || "",
            productImage:
                getCharacteristicValue(node.nodeProperties, "product_image") ||
                "",
            room: node.state.room || "",
            manufacturer: node.state.manufacturer || "",
            cableLength: getCableLength(node)
        }
    } else {
        return {
            id: node.id,
            name: node.name || "",
            quantity: node.node.quantity || "",
            productImage: getCharacteristicValue(node, "product_image") || "",
            room: node.node.state.room || "",
            manufacturer: getCharacteristicValue(node, "manufacturer") || "",
            cableLength: getCharacteristicValue(node, "cable_length") || "",
            additionalInformation: `${L10n.format(
                TranslationConstants.General.CABLE
            )} ${cableCount}`
        }
    }
}

const getCableLength = (node: any, parseAsNumber?: boolean) => {
    const cableLength =
        node.state["/Kabellänge"] || node.state.cable_length || ""
    if (parseAsNumber) {
        return Number.parseFloat(cableLength)
    } else {
        return cableLength
    }
}
