import React from "react"

import { L10n } from "@encoway/l10n"

import { TranslationConstants } from "../../../constants/translationConstants"
import Dialog from "../../Dialog/Dialog"

type Props = {
    fileName: string
    onClickPrimaryHandler: () => void
    onClickSecondaryHandler: () => void
}

export function FloorPlanUploadConfirmationDialog(props: Readonly<Props>) {
    return (
        <Dialog
            title={L10n.format(
                TranslationConstants.FloorPlan.CONFIRMATION_TITLE
            )}
            showPrimaryButton={true}
            showSecondaryButton={true}
            primaryButtonTitle={L10n.format(
                TranslationConstants.FloorPlan.CONFIRMATION_PRIMARY_BUTTON
            )}
            secondaryButtonTitle={L10n.format(
                TranslationConstants.FloorPlan.CONFIRMATION_SECONDARY_BUTTON
            )}
            onClickPrimary={props.onClickPrimaryHandler}
            onClickSecondary={props.onClickSecondaryHandler}
        >
            <>
                <div>
                    {L10n.format(
                        TranslationConstants.FloorPlan.CONFIRMATION_HEADING
                    )}
                </div>
                <br />
                <div>
                    {L10n.format(
                        TranslationConstants.FloorPlan.CONFIRMATION_UNSAVED
                    )}{" "}
                    {props.fileName}
                </div>
            </>
        </Dialog>
    )
}
