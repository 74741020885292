import axios from "axios"
import { saveAs } from "file-saver"
import moment from "moment"
import React from "react"

import { L10n } from "@encoway/l10n"

import { TranslationConstants } from "../constants/translationConstants"
import { useDetermineResultBom } from "../features/bom/useDetermineResultBom"
import { DefaultPrimaryButton } from "./DefaultPrimaryButton/DefaultPrimaryButton"

export default function CsvExportButton() {
    const { determineResultBom } = useDetermineResultBom()

    const getTimestamp = () => {
        return moment(new Date()).format("YYYY-MM-DD_HH-mm-ss")
    }

    const exportAsCsv = async () => {
        const baseUrl = window.encoway.baseUrl
        const csvData = await determineResultBom()
        if (csvData) {
            await axios
                .post(`${baseUrl}/api/export/csvDownload`, { csvData })
                .then((response: any) => {
                    if (response.status === 200) {
                        const blob = new Blob(["\uFEFF" + response.data], {
                            type: "text/csv;charset=utf-8"
                        })
                        saveAs(blob, "data_" + getTimestamp() + ".csv")
                    }
                })
                .catch((error: any) =>
                    console.error(
                        "There was a problem with your fetch operation.",
                        error
                    )
                )
        }
    }

    return (
        <DefaultPrimaryButton onClick={exportAsCsv}>
            {L10n.format(TranslationConstants.Toolbar.BUTTON_CSV_EXPORT)}
        </DefaultPrimaryButton>
    )
}
